import { ModuleTree } from 'vuex';
import IRootState from './rootState';
import accountsStore from './accountsStore';
import contactsStore from './contactsStore';
import webhookIntegrationsStore from './webhookIntegrationsStore';
import alarmsStore from './alarmsStore';
import alarmOptionsStore from './alarmOptionsStore';
import selfAccountStore from './selfAccountStore';
import evaluationsStore from './evaluationsStore';
import entryPointArgsStore from './entryPointArgsStore';

const stores = { accountsStore, contactsStore, webhookIntegrationsStore, alarmsStore, alarmOptionsStore, selfAccountStore, evaluationsStore, entryPointArgsStore } as ModuleTree<IRootState>;

export * from './rootState';

export { stores };
export default stores;
