import { ActionContext, Module } from 'vuex';
import IRootState from './rootState';
import { LicenceAccount } from '@/repositories';

// state
interface IAccountsState {
    accounts: LicenceAccount[];
}

// vuex module
class AccountsStore implements Module<IAccountsState, IRootState> {
    namespaced = true;

    // state
    state: IAccountsState = {
        accounts: []
    };

    // getters
    getters = {
        getAccounts(s: IAccountsState): LicenceAccount[] {
            return s.accounts;
        }
    };

    // mutations
    mutations = {
        setAccounts(s: IAccountsState, accounts: []): void {
            s.accounts = accounts;
        },
        addAccount(s: IAccountsState, account: LicenceAccount): void {
            s.accounts.push(account);
        },
        updateAccount(s: IAccountsState, account: LicenceAccount): void {
            const index = s.accounts.findIndex(a => a.id === account.id);
            Object.assign(s.accounts[index], account);
        },
        deleteAccount(s: IAccountsState, account: LicenceAccount): void {
            const index = s.accounts.findIndex(a => a.id === account.id);
            s.accounts.splice(index, 1);
        }
    };

    // actions
    actions = {
        async loadAccounts({ rootState, commit }: ActionContext<IAccountsState, IRootState>): Promise<void> {
            const accounts = await rootState.container.accountsClient.getAll();
            commit('setAccounts', accounts);
        },
        async saveAccount({ rootState, commit }: ActionContext<IAccountsState, IRootState>, account: LicenceAccount): Promise<void> {
            if (account.id === undefined) {
                rootState.container.accountsClient.post(account).then(result => {
                    commit('addAccount', result);
                });
            } else {
                rootState.container.accountsClient.put(account.id, account).then(result => {
                    commit('updateAccount', result);
                });
            }
        },
        async deleteAccount({ rootState, commit }: ActionContext<IAccountsState, IRootState>, account: LicenceAccount): Promise<void> {
            rootState.container.accountsClient.delete(account.id).then(() => {
                commit('deleteAccount', account);
            });
        }
    };
}

export { IAccountsState, AccountsStore };

// export an instance of the store by default
export default new AccountsStore();
