import { ActionContext, Module } from 'vuex';
import IRootState from './rootState';
import { RuleData } from '@/repositories'; //

// state
interface IAlarmOptionsState {
    ruleData: RuleData;
}

// vuex module
class alarmOptionsStore implements Module<IAlarmOptionsState, IRootState> {
    namespaced = true;

    // state
    state: IAlarmOptionsState = {
        ruleData: new RuleData()
    };

    // getters
    getters = {
        getRuleData(s: IAlarmOptionsState): RuleData {
            return s.ruleData;
        }
    };

    // mutations
    mutations = {
        setRuleData(s: IAlarmOptionsState, ruleData: RuleData): void {
            s.ruleData = ruleData;
        }
    };

    // actions
    actions = {
        async loadRuleData({ rootState, commit }: ActionContext<IAlarmOptionsState, IRootState>): Promise<void> {
            const ruleData = await rootState.container.alarmOptionsClient.get(undefined); // need new api endpoint for authenticated get
            commit('setRuleData', ruleData);
        }
    };
}

export { IAlarmOptionsState, alarmOptionsStore };

// export an instance of the store by default
export default new alarmOptionsStore();
