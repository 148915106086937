import { ActionContext, Module } from 'vuex';
import IRootState from './rootState';
import { LicenceAccount } from '@/repositories';

// state
interface ISelfAccountState {
    account: LicenceAccount;
}

// vuex module
class selfAccountStore implements Module<ISelfAccountState, IRootState> {
    namespaced = true;

    // state
    state: ISelfAccountState = {
        account: new LicenceAccount()
    };

    // getters
    getters = {
        getCredits(s: ISelfAccountState): number {
            return s.account.credits || 0;
        },
    };

    // mutations
    mutations = {
        setCredits(s: ISelfAccountState, credits: number): void {
            s.account.credits = credits;
        },
    };

    // actions
    actions = {
        async loadCredits({ rootState, commit }: ActionContext<ISelfAccountState, IRootState>): Promise<void> {
            const selfaccount = await rootState.container.selfClient.get();
            if (selfaccount) {
                commit('setCredits', selfaccount.credits);
            }
        },
    };
}

export { ISelfAccountState, selfAccountStore};

// export an instance of the store by default
export default new selfAccountStore();
