import { ActionContext, Module } from 'vuex';
import IRootState from './rootState';
import { ConfiguredAlarm } from '@/repositories'; //

// state
interface IAlarmsState {
    alarms: ConfiguredAlarm[];
}

// vuex module
class alarmsStore implements Module<IAlarmsState, IRootState> {
    namespaced = true;

    // state
    state: IAlarmsState = {
        alarms: []
    };

    // getters
    getters = {
        getAlarms(s: IAlarmsState): ConfiguredAlarm[] {
            return s.alarms;
        }
    };

    // mutations
    mutations = {
        setAlarms(s: IAlarmsState, alarms: []): void {
            s.alarms = alarms;
        },
        addAlarm(s: IAlarmsState, alarm: ConfiguredAlarm): void {
            s.alarms.push(alarm);
        },
        updateAlarm(s: IAlarmsState, alarm: ConfiguredAlarm): void {
            const index = s.alarms.findIndex(a => a.id === alarm.id);
            Object.assign(s.alarms[index], alarm);
        },
        deleteAlarm(s: IAlarmsState, alarm: ConfiguredAlarm): void {
            const index = s.alarms.findIndex(a => a.id === alarm.id);
            s.alarms.splice(index, 1);
        }
    };

    // actions
    actions = {
        async loadAlarms({ rootState, commit }: ActionContext<IAlarmsState, IRootState>): Promise<void> {
            const alarms = await rootState.container.alarmsClient.get();
            commit('setAlarms', alarms);
        },
        async saveAlarm({ rootState, commit }: ActionContext<IAlarmsState, IRootState>, alarm: ConfiguredAlarm): Promise<void> {
            if (alarm.id === undefined) {
                const result = await rootState.container.alarmsClient.post(alarm);
                commit('addAlarm', result);
            } else {
                const result = await rootState.container.alarmsClient.put(alarm.id, alarm);
                commit('updateAlarm', result);
            }
        },
        async deleteAlarm({ rootState, commit }: ActionContext<IAlarmsState, IRootState>, alarm: ConfiguredAlarm): Promise<void> {
            await rootState.container.alarmsClient.delete(alarm.id);
            commit('deleteAlarm', alarm);
        }
    };
}

export { IAlarmsState, alarmsStore };

// export an instance of the store by default
export default new alarmsStore();
