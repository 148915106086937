import { ActionContext, Module } from 'vuex';
import IRootState from './rootState';
import { EvaluationRun } from '@/repositories';

// state
interface IEvaluationRunsState {
    evaluations: EvaluationRun[];
}

// vuex module
class evaluationsStore implements Module<IEvaluationRunsState, IRootState> {
    namespaced = true;

    // state
    state: IEvaluationRunsState = {
        evaluations: []
    };

    // getters
    getters = {
        getEvaluations(s: IEvaluationRunsState): EvaluationRun[] {
            return s.evaluations;
        }
    };

    // mutations
    mutations = {
        setEvaluations(s: IEvaluationRunsState, evaluations: []): void {
            s.evaluations = evaluations;
        }
    };

    // actions
    actions = {
        async loadEvaluations({ rootState, commit }: ActionContext<IEvaluationRunsState, IRootState>, limit: 10): Promise<void> {
            const evaluations = await rootState.container.evaluationsClient.getAll(true, limit);
            commit('setEvaluations', evaluations);
        }
    };
}

export { IEvaluationRunsState, evaluationsStore };

// export an instance of the store by default
export default new evaluationsStore();
