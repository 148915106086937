import { ActionContext, Module } from 'vuex';
import IRootState from './rootState';
import { EntryPointArgs } from '@/repositories';

// state
interface IEntryPointArgsState {
    entryPointArgs: EntryPointArgs;
}

// vuex module
class EntryPointArgsStore implements Module<IEntryPointArgsState, IRootState> {
    namespaced = true;

    // state
    state: IEntryPointArgsState = {
        entryPointArgs: new EntryPointArgs()
    };

    // getters
    getters = {
        getEntryPointArgs(s: IEntryPointArgsState): EntryPointArgs {
            return s.entryPointArgs;
        }
    };

    // mutations
    mutations = {
        setEntryPointArgs(s: IEntryPointArgsState, entryPointArgs: EntryPointArgs): void {
            s.entryPointArgs = entryPointArgs;
        }
    };

    // actions
    actions = {
        async loadEntryPointArgs({ rootState, commit }: ActionContext<IEntryPointArgsState, IRootState>): Promise<void> {
            const entryPointArgs = await rootState.container.entryPointArgsClient.get();
            commit('setEntryPointArgs', entryPointArgs);
        }
    };
}

export { IEntryPointArgsState, EntryPointArgsStore };

// export an instance of the store by default
export default new EntryPointArgsStore();
