import { ActionContext, Module } from 'vuex';
import IRootState from './rootState';
import { WebhookIntegration } from '@/repositories';
import { SlackTokenRequest } from '@/repositories';

// state
interface IWebhookIntegrationsState {
    webhooks: WebhookIntegration[];
}

// vuex module
class webhookIntegrationsStore implements Module<IWebhookIntegrationsState, IRootState> {
    namespaced = true;

    // state
    state: IWebhookIntegrationsState = {
        webhooks: []
    };

    // getters
    getters = {
        getWebhooks(s: IWebhookIntegrationsState): WebhookIntegration[] {
            return s.webhooks;
        }
    };

    // mutations
    mutations = {
        setWebhooks(s: IWebhookIntegrationsState, webhooks: []): void {
            s.webhooks = webhooks;
        },
        addWebhook(s: IWebhookIntegrationsState, webhook: WebhookIntegration): void {
            s.webhooks.push(webhook);
        },
        deleteWebhook(s: IWebhookIntegrationsState, webhook: WebhookIntegration): void {
            const index = s.webhooks.findIndex(a => a.id === webhook.id);
            s.webhooks.splice(index, 1);
        }
    };

    // actions
    actions = {
        async loadWebhooks({ rootState, commit }: ActionContext<IWebhookIntegrationsState, IRootState>): Promise<void> {
            const webhooks = await rootState.container.integrationsClient.get();
            commit('setWebhooks', webhooks);
        },
        async saveWebhook({ rootState, commit }: ActionContext<IWebhookIntegrationsState, IRootState>, webhook: WebhookIntegration): Promise<void> {
            if (webhook.id === undefined) {
                const result = await rootState.container.integrationsClient.postWebhook(webhook);
                commit('addWebhook', result);
            }
        },
        async postSlackToken({ rootState, commit }: ActionContext<IWebhookIntegrationsState, IRootState>, code: string): Promise<void> {
            var req = new SlackTokenRequest();
            req.code = code;
            const result = await rootState.container.integrationsClient.postSlackToken(req);
            console.log('postSlackToken result', result);
            commit('addWebhook', result);
        },
        async deleteWebhook({ rootState, commit }: ActionContext<IWebhookIntegrationsState, IRootState>, webhook: WebhookIntegration): Promise<void> {
            await rootState.container.integrationsClient.delete(webhook.id);
            commit('deleteWebhook', webhook);
        }
    };
}

export { IWebhookIntegrationsState, webhookIntegrationsStore };

// export an instance of the store by default
export default new webhookIntegrationsStore();
