import { ActionContext, Module } from 'vuex';
import IRootState from './rootState';
import { NotificationContact } from '@/repositories';

// state
interface INotificationContactsState {
    contacts: NotificationContact[];
}

// vuex module
class contactsStore implements Module<INotificationContactsState, IRootState> {
    namespaced = true;

    // state
    state: INotificationContactsState = {
        contacts: []
    };

    // getters
    getters = {
        getContacts(s: INotificationContactsState): NotificationContact[] {
            return s.contacts;
        }
    };

    // mutations
    mutations = {
        setContacts(s: INotificationContactsState, contacts: []): void {
            s.contacts = contacts;
        },
        addContact(s: INotificationContactsState, contact: NotificationContact): void {
            s.contacts.push(contact);
        },
        updateContact(s: INotificationContactsState, contact: NotificationContact): void {
            const index = s.contacts.findIndex(a => a.id === contact.id);
            Object.assign(s.contacts[index], contact);
        },
        deleteContact(s: INotificationContactsState, contact: NotificationContact): void {
            const index = s.contacts.findIndex(a => a.id === contact.id);
            s.contacts.splice(index, 1);
        }
    };

    // actions
    actions = {
        async loadContacts({ rootState, commit }: ActionContext<INotificationContactsState, IRootState>): Promise<void> {
            const contacts = await rootState.container.contactsClient.get();
            commit('setContacts', contacts);
        },
        async saveContact({ rootState, commit }: ActionContext<INotificationContactsState, IRootState>, contact: NotificationContact): Promise<void> {
            if (contact.id === undefined) {
                const result = await rootState.container.contactsClient.post(contact);
                commit('addContact', result);
            } else {
                const result = await rootState.container.contactsClient.put(contact.id, contact);
                commit('updateContact', result);
            }
        },
        async deleteContact({ rootState, commit }: ActionContext<INotificationContactsState, IRootState>, contact: NotificationContact): Promise<void> {
            await rootState.container.contactsClient.delete(contact.id);
            commit('deleteContact', contact);
        }
    };
}

export { INotificationContactsState, contactsStore };

// export an instance of the store by default
export default new contactsStore();
