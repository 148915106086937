import '@mdi/font/css/materialdesignicons.css';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const vuetify = async () => {
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    const { default: Vuetify } = await import(/* webpackChunkName: "vuetify" */ 'vuetify/lib');
    Vue.use(Vuetify);

    const mq = window.matchMedia('(prefers-color-scheme: dark)');

    return new Vuetify({
        theme: { dark: mq.matches },
        icons: { iconfont: 'mdi' }
    });
};

export default vuetify;
