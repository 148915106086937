import AlarmsComponent from '@/views/alarms.vue';
import ContactsComponent from '@/views/contacts.vue';
import IntegrationsComponent from '@/views/integrations.vue';
import AccountsComponent from '@/views/accounts.vue';
import EvaluationsComponent from '@/views/evaluations.vue';
import HistoricalComponent from '@/views/historical.vue';
import NemWatchComponent from '@/views/nem-watch.vue';
import SlackRedirectComponent from '@/views/slack_redirect.vue';
import NotFoundComponent from '@/views/404.vue';

const routes = [
    { path: '/', redirect: 'alarms' },
    { path: '/alarms', name: 'alarms', component: AlarmsComponent },
    { path: '/contacts', name: 'contacts', component: ContactsComponent },
    { path: '/integrations', name: 'integrations', component: IntegrationsComponent },
    { path: '/accounts', name: 'accounts', component: AccountsComponent },
    { path: '/evaluations', name: 'evaluations', component: EvaluationsComponent },
    { path: '/historical', name: 'historical', component: HistoricalComponent },
    { path: '/nem-watch-v6', name: 'nem-watch-v6', component: NemWatchComponent },
    { path: '/slack_redirect', name: 'slack_redirect', component: SlackRedirectComponent },
    { path: '/:catchAll(.*)', component: NotFoundComponent, name: 'NotFound' },
];

export { routes };

export default routes;
